// .icon_container {
//   width: 80px;
//   height: 80px;
// }

// body:not([class*="rtl"]) .icon_container {
//   margin-right: 16px;
// }

// body[class*="rtl"] .icon_container {
//   margin-left: 16px;
// }

// .icon_container span {
//   background-color: #fb99183a;
// }

// counter sections classes
.counterCard {
  border-radius: 16px !important;
  padding: 16px;
}

.cardBody {
  border-radius: 16px;
  padding: 12px !important;
}


.counter {
  font-size: 40px;
  line-height: 56px;
  font-weight: 600;
}

.counterSmallText {
  font-size: 12px;
  font-weight: 400;
  color: #969ba0;
}

.chartIcon {
  width: 18.5px;
  height: 12px;
}

.chartsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 1400px) {
  .chartsContainer {
    grid-template-columns: 1fr;
  }
}

// customer map
.customerMapTitle {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}

.customerMapDescription {
  font-size: 12px;
  color: #969ba0;
  line-height: 16px;
}

.linkesContainer {
  padding: 8px;
  border-radius: 8px;
}

// revenue
.revenueCardBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// Manage dashboard layout
.manageDashboardLayout {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: space-between;
  padding: 78px;
  border-radius: 16px;
  background-color: #3e4954;
  color: white;
  position: relative;
  overflow: hidden;
  gap: 5px;
}
.manageDashboardLayoutImg {
  width: 48px;
  height: 48px;
  margin-top: 150px;
}
.manageDashboardLayout h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.manageDashboardLayout p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.manageDashboardLayout div {
  gap: 8px;
}
.manageDashboardLayout i {
  font-weight: 700;
  font-size: 24px;
  line-height: 16px;
}

.manageDashboardLayoutSvg {
  width: 282px;
  height: 282px;
  position: absolute;
  top: -30px;
  right: -30px;
}

// Order summary
.manageOrder {
  border-radius: 16px;
  min-height: 72px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  flex-wrap: wrap;
}

.subContainerSection {
  display: flex;
  gap: 50px;
}
.ordersNumber {
  padding: 8px;
  width: 88px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
}

.newOrderContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.newOrder {
  font-weight: 500;
  // font-size: 16px;
  line-height: 24px;
  color: #3e4954

}

.newOrderDot {
  width: 11px;
  height: 11px;
  border-radius: 11px;
  background-color:  #3e4954;
}

.newOrderLink {
  font-weight: 500;
  line-height: 24px;
  // color: #2f4cdd;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 11px 12px !important;
}

.orderSummaryCardContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .orderSummaryCardContainer {
    justify-content: center;
  }
  .orderSummaryCardContainer div {
    width: 100%;
  }
}

.orderSummaryCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  border: 1px solid #f2f2f2;
  border-radius: 16px;
  height: 104px;
  min-width: 165px;
  width: 30%;
  text-align: center;
}

.orderSummaryCardValue {
  font-weight: 600;
  line-height: 48px;
}

.orderSummaryCardTitle {
  font-weight: 500;
  line-height: 24px;
  color: #969ba0;
}

.dountChartProgressContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dountChartTitle {
  font-weight: 500;
  // font-size: 16px;
  line-height: 24px;
}

.dountChartProgressParent {
  max-width: 150px;
  height: 8px;
  border-radius: 8px;
  background-color: #f3f3f3;
}

.dountChartProgressValue {
  font-weight: 500;
  // font-size: 16px;
  line-height: 24px;
  color: #969ba0;
}

// Revenue
.revnueLabel {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.revnueLabelText {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.revnueSvgTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #969ba0;
}

.revnueChart {
  width: 24px;
  height: 24px;
}

.revnueMoney {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
